import React, {Fragment} from 'react'
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import ServiceBuilding from '../../components/ServiceBuilding'
import Footer from '../../components/footer'



const ServiceBuildingPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'ビル管理事業'} pagesub={'Building Management'}/>
            <ServiceBuilding/>
            <Footer/>
        </Fragment>
    )
};
export default ServiceBuildingPage;
