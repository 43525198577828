import React from "react";
import ServiceBg from "../../img/AdobeStock_445203368.jpg";
import Img1 from "../../img/repair-1.jpg";
import Img2 from "../../img/repair-2.jpg";
import Img3 from "../../img/repair-3.jpg";
import Img4 from "../../img/repair-4.jpg";
import Img5 from "../../img/AdobeStock_260282528.jpg";
import Img6 from "../../img/AdobeStock_478233385.jpg";

import "./style.css";

const ServiceRepair = () => {
  return (
    <div>
      <section className="service-video-box-area" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="site-heading">
                <h3 className="sub-title">ABOUT</h3>
                <h2 className="section-title">
                  大事な資産を修繕
                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-video-box-text">
                <p>弊社はリペアワーキング事業を行っています。お客様の大事な資産をリペア（修理・修繕）することで、建築物のライフサイクルをより永くするための事業です。</p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-video-box">
                <img src={ServiceBg} alt="img" />
              </div>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-8">
              <div className="project-details-top-text">
                <p>あるお客様から「（施工費が多額の）大きな物件の時だとすぐに来るけど、ちょっと壊れたので修理依頼をしてもなかなか来てくれないし、見積もりもしてもらえず、大変困っていた」というお話を聞き、すぐに対応したら大変喜ばれたのがこの事業を始めたきっかけです。</p>
                <p>修理・修繕はスピードが命です。お客様が困っていることを早く解決します。建築物の内装解体撤去から内装工事全般および設備工事、外壁の塗装のリペア（修理・修繕）工事を行います。</p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="project-details-type">
                <div className="row">
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img1} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img2} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                  <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img3} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img4} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img5} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img6} alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ServiceRepair;
