import React from "react";

import bg from "../../img/AdobeStock_13948851.jpg";
import img1 from "../../img/AdobeStock_278648995_346317573.jpg";
import img2 from "../../img/about-2.jpg";

import "./style.css";

const About = () => {
  return (
    <section className="about-area" style={{ backgroundImage: `url(${bg})` }} id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="about-left" data-aos="fade-right">
              <div className="site-heading">
                <h3 className="sub-title">ABOUT US</h3>
                <h2 className="section-title">
                  お客様の大事な資産をより価値あるものに
                </h2>
              </div>
              <p>大型商業施設、飲食店等を設計施工する会社で営業・制作・現場管理等を23年経験し、その後インテリアデザイン会社で営業・業務進行管理・CM（コンストラクション・マネジメント）を3年経験。建築物を維持管理するビルメンテナンス会社の営業、業務管理等の統括責任者を10年経験しました。</p>
              <p>建築物に携わったこの36年の経験を活かし、ただ単に建物を維持管理するのではなく、お客様の大事な資産をより価値あるものにするために、会社を設立しました。</p>
              <div className="about-signature">
                <div className="signature-right">
                  <p>代表取締役</p>
                  <h3>谷川 善昭</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ms-lg-5 col-md-6">
            {/*<div className="about-counter">
              <div className="counter-box">
                <h2>
                  <span className="counter">
                    <CountUp start={0} end={36} duration={5} />
                  </span>
                </h2>
                <p>
                  年の経験
                </p>
              </div>
              <div className="counter-box">
                <h2>
                  <span className="counter">
                    <CountUp start={0} end={100} duration={5} />
                  </span>
                  +
                </h2>
                <p>
                  件の実績
                </p>
              </div>
              <div className="counter-box">
                <h2>
                  <span className="counter">
                    <CountUp start={0} end={180} duration={5} />
                  </span>
                  +
                </h2>
                <p>
                  Awards <br />
                  Winning
                </p>
              </div>
            </div>*/}
            <div className="about-right mt-5 mt-md-0" data-aos="fade-left">
              <img className="about_img_1" src={img2} alt="代表取締役 谷川善昭" />
              <img className="about_img_2" src={img1} alt="background" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
