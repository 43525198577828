import React from "react";
import ContactForm from "../ContactForm";
import "./style.css";

const Contactpage = () => {
  return (
    <section className="contact-page-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="contact-form">
              <div className="site-heading" data-aos="fade-up">
                <h3 className="sub-title">Get Quote</h3>
                <h2 className="section-title">お問い合わせ・お見積もり</h2>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="contact-page-left">
              <h3>事業本部</h3>
              <div className="contact-info">
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fas fa-map-marker-alt" />
                  </div>
                  <div className="contact-info-text">
                    <h5>Address</h5>
                    <p>
                      千葉県柏市あけぼの2-1-29
                    </p>
                  </div>
                </div>
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fas fa-phone-alt" />
                  </div>
                  <div className="contact-info-text">
                    <h5>Phone</h5>
                    <p>04-7114-2767</p>
                  </div>
                </div>
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fas fa-envelope" />
                  </div>
                  <div className="contact-info-text">
                    <h5>Email</h5>
                    <p>y.tanikawa<br class="d-inline d-sm-none" />@rockproperty.co.jp</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="contact-page-right">
              <iframe
                title="map-kashiwa"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6466.4602690784195!2d139.96683896783003!3d35.86787678212896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60189ce60696aaab%3A0x62e71aa37873209f!2z5qCq5byP5Lya56S-44Ot44OD44Kv44OX44Ot44OR44OG44Kj!5e0!3m2!1sja!2sjp!4v1708996757419!5m2!1sja!2sjp"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-5">
          <div className="col-lg-5">
            <div className="contact-page-left">
              <h3>東京オフィス</h3>
              <div className="contact-info">
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fas fa-map-marker-alt" />
                  </div>
                  <div className="contact-info-text">
                    <h5>Address</h5>
                    <p>
                      東京都渋谷区富ヶ谷1-8-2
                    </p>
                  </div>
                </div>
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fas fa-phone-alt" />
                  </div>
                  <div className="contact-info-text">
                    <h5>Phone</h5>
                    <p>03-6804-8085</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="contact-page-right">
              <iframe
                title="map-tokyo"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.3175316733586!2d139.68774487702018!3d35.66918267259115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f34ca702b265%3A0xd57020d8a5a1e1d3!2z44CSMTUxLTAwNjMgVG9reW8sIFNoaWJ1eWEgQ2l0eSwgVG9taWdheWEsIDEtY2jFjW1l4oiSOOKIkjIg5Luj44CF5pyo44Ob44O844Og44K6!5e0!3m2!1sja!2sjp!4v1708996477064!5m2!1sja!2sjp"
                loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contactpage;
