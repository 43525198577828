import React from "react";
import { Link } from "react-router-dom";

import serviceImg1 from "../../img/AdobeStock_305638970.jpg";
import serviceImg2 from "../../img/service-2.jpg";
import serviceImg3 from "../../img/AdobeStock_300015712.jpg";

import "./style.css";

const ServiceSection = () => {
  return (
    <section className="service-area" id="services">
      <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="site-heading">
            <h3 className="sub-title">OUR SERVICES</h3>
            <h2 className="section-title">事業案内</h2>
          </div>
        </div>
      </div>
        <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div
                className="service-box"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="service-icon">
                  <i className="far fa-building" />
                </div>
                <div className="service-text">
                  <h3>ビル管理事業</h3>
                  <p>お客様の大事な資産を総合的に管理することで、より永く維持するための事業</p>
                  <Link to="/service-building" className="cta-btn btn-border">
                    詳細をみる
                  </Link>
                </div>
                <img src={serviceImg1} alt="img" />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div
                className="service-box"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <div className="service-icon">
                  <i className="fas fa-tools" />
                </div>
                <div className="service-text">
                  <h3>リペアワーキング事業</h3>
                  <p>お客様の大事な資産を修繕することで、ライフサイクルをより長くするための事業</p>
                  <Link to="/service-repair" className="cta-btn btn-border">
                    詳細をみる
                  </Link>
                </div>
                <img src={serviceImg2} alt="img" />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div
                className="service-box"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="service-icon">
                  <i className="fas fa-couch" />
                </div>
                <div className="service-text">
                  <h3>インテリアワーキング事業</h3>
                  <p>お客様の大事な資産を新装・改装工事することで、 新たな潤いを導き出すための事業</p>
                  <Link to="/service-interior" className="cta-btn btn-border">
                    詳細をみる
                  </Link>
                </div>
                <img src={serviceImg3} alt="img" />
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
