import React, {Fragment} from 'react'
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import ServiceRepair from '../../components/ServiceRepair'
import Footer from '../../components/footer'



const ServiceRepairPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'リペアワーキング事業'} pagesub={'Repair Working'}/>
            <ServiceRepair/>
            <Footer/>
        </Fragment>
    )
};
export default ServiceRepairPage;
