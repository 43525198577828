import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import "./style.css";

const Footer = () => {

  return (
    <footer className="footer-area">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="single-footer">
                <h3>株式会社ロックプロパティ</h3>
                <p>お客様の大事な資産をより価値あるものに</p>
                <h4>事業本部</h4>
                <ul className="footer-contact">
                  <li>
                    <i className="fas fa-map" /> 千葉県柏市あけぼの2-1-29
                  </li>
                  <li>
                    <i className="fas fa-phone-square-alt" /> 04-7114-2767
                  </li>
                </ul>
                <h4>東京オフィス</h4>
                <ul className="footer-contact">
                  <li>
                    <i className="fas fa-map" /> 東京都渋谷区富ヶ谷1-8-2
                  </li>
                  <li>
                    <i className="fas fa-phone-square-alt" /> 03-6804-8085
                  </li>
                </ul>
                <Link to="/contact" className="btn btn-fill"><i class="far fa-envelope"></i> お問い合わせ</Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="single-footer">
                <h3>Contents</h3>
                <ul>
                  <li>
                    <HashLink to="/">Home</HashLink>
                  </li>
                  <li>
                    <HashLink to="/#about">About Us</HashLink>
                  </li>
                  <li>
                    <HashLink to="/#services">Services</HashLink>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="single-footer">
                <h3>Our Services</h3>
                <ul>
                  <li>
                    <Link to="/service-building">ビル管理事業</Link>
                  </li>
                  <li>
                    <Link to="/service-repair">リペアワーキング事業</Link>
                  </li>
                  <li>
                    <Link to="/service-interior">インテリアワーキング事業</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="fade_rule" />
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>&copy; Rock Property Co. Ltd. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
