import React from "react";
import ServiceBg from "../../img/AdobeStock_52669562.jpg";
import Img1 from "../../img/AdobeStock_169381271.jpg";
import Img2 from "../../img/AdobeStock_447922922.jpg";
import Img3 from "../../img/AdobeStock_249444411.jpg";
import Img4 from "../../img/AdobeStock_281173019.jpg";
import Img5 from "../../img/AdobeStock_166474053.jpg";
import Img6 from "../../img/AdobeStock_213598260.jpg";

import "./style.css";

const ServiceBuilding = () => {
  return (
    <div>
      <section className="service-video-box-area" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="site-heading">
                <h3 className="sub-title">ABOUT</h3>
                <h2 className="section-title">
                  大事な資産を総合管理
                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-video-box-text">
                <p>弊社はビル管理事業を行なっています。お客様の大事な資産を総合的に管理することで、その価値をより永く維持するための事業です。
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-video-box">
                <img src={ServiceBg} alt="img" />
              </div>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-8">
              <div className="project-details-top-text">
                <h3>清掃管理業務</h3>
                <p>日々人員を配置し、清掃管理を徹底し、建物の美観と衛生環境を常に維持管理する日常清掃と、日常清掃では落としきれない汚れを定期的に除去し、長期的に維持管理する定期清掃があります。また、定期清掃でも行わない場所やブラインド、外壁等の特別清掃があります。</p>
                <h3 class="mt-5">設備保守点検業務</h3>
                <p>人間で言うと内臓と同じで、日々保守管理することで正常に保ちます。この正常が当たり前で、異常になると人間は生きれなくなるし、建築物は機能しなくなります。当たり前の正常な状態を維持するために保守管理します。電気設備、給排水設備、空調設備、消防設備、エレベーター保守点検等があります。</p>
                <h3 class="mt-5">衛生管理業務</h3>
                <p>水、空気、害虫防除等があります。水は、飲料水（貯水槽清掃）と汚れた水の排水装置（汚水・雑排水槽清掃）の管理業務を行います。空気は、建築物のご利用者様の健康・安全を守るための管理業務です。定期的に空気環境を測定し、基準値を超えている場合は即時に報告、改善を促します。</p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="project-details-type">
                <div className="row">
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img1} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img2} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img3} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img4} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img5} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img6} alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ServiceBuilding;
