import React, { Component } from "react";
import { init, send } from 'emailjs-com';

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    notes: "",
    error: {},
    isSubmitted: false,
  };
  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();

    const { name, email, notes, error, isSubmitted } = this.state;

    if (name === "") {
      error.name = "お名前を入力してください";
    }
    if (email === "") {
      error.email = "メールアドレスを入力してください";
    }
    if (notes === "") {
      error.notes = "お問い合わせ内容を入力してください";
    }

    if (error) {
      this.setState({
        error,
      });
    }
    if (
      error.name === "" &&
      error.email === "" &&
      error.notes === ""
    ) {
      this.setState({
        name: "",
        email: "",
        notes: "",
        error: {}
      });
      this.setState((prevState) => ({
            isSubmitted: true}), () => {
            // console.log(this.state.isSubmitted)
      });

        //メール送信処理
        init('user_gxDW7CG8xhX3H1ga2Eqp7');
        const emailjsServiceId = 'service_gyj5p6p';
        const emailjsTemplateId = 'template_7cynal9';
        const templateParams = {
            from_name: name,
            from_email: email,
            message: notes
        };
        send(emailjsServiceId, emailjsTemplateId, templateParams).then(() => {
          console.log(isSubmitted);
        });
    }
  };

  render() {
    const { name, email, notes, error } = this.state;
    if (this.state.isSubmitted) {
        return (
          <p class="text-center">ありがとうございます。送信が完了しました。</p>
        );
    } else {
        return (
          <form onSubmit={this.submitHandler} className="form">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="form-field">
                  <input
                    value={name}
                    onChange={this.changeHandler}
                    type="text"
                    name="name"
                    placeholder="お名前"
                  />
                  <p class="mb-0">{error.name ? error.name : ""}</p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="form-field">
                  <input
                    onChange={this.changeHandler}
                    value={email}
                    type="email"
                    name="email"
                    placeholder="メールアドレス"
                  />
                  <p class="mb-0">{error.email ? error.email : ""}</p>
                </div>
              </div>
              <div className="col-lg-12 col-sm-12">
                <div className="form-field">
                  <textarea
                    onChange={this.changeHandler}ß
                    value={notes}
                    name="notes"
                    placeholder="お問い合わせ内容"
                  ></textarea>
                  <p class="mb-0">{error.notes ? error.notes : ""}</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-form-action">
                  <button className="form-button" type="submit">
                    送信する
                  </button>
                </div>
              </div>
            </div>
          </form>
        );
    }

  }
}
export default ContactForm;
