import React from "react";
import ServiceBg from "../../img/AdobeStock_247180439.jpg";
import Img1 from "../../img/AdobeStock_219471483.jpg";
import Img2 from "../../img/AdobeStock_39399584.jpg";
import Img3 from "../../img/AdobeStock_43508288.jpg";
import Img4 from "../../img/AdobeStock_48430476.jpg";
import Img5 from "../../img/AdobeStock_213435822.jpg";
import Img6 from "../../img/AdobeStock_274568994.jpg";

import "./style.css";

const ServiceInterior = () => {
  return (
    <div>
      <section className="service-video-box-area" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="site-heading">
                <h3 className="sub-title">ABOUT</h3>
                <h2 className="section-title">
                  大事な資産に潤いを
                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-video-box-text">
                <p>弊社はインテリアワーキング事業を行っています。お客様の大事な資産を新装・改装工事することで、 新たな潤いを導き出すための事業です。</p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-video-box">
                <img src={ServiceBg} alt="img" />
              </div>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-8">
              <div className="project-details-top-text">
                <p>新装工事や改装工事を行うことは、店舗であればより売り上げが伸び、いわゆる「繁盛店」を作ることで、会社全体のモチベーションが上がり、今まで以上の社業発展の方法となります。また、ビルオーナーにとってはテナントの業績が上がれば、ビル全体の活性化に繋がり新たな潤いを導きます。</p>
                <p>企画、デザイン、設計、施工と一気通貫で行うことで、ハイコストパフォーマンスをお届けします。また、ビルオーナーとテナントを結び付けるマッチングサービスのリーシング業務も行っています。</p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="project-details-type">
                <div className="row">
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img1} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img2} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img3} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                  <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img4} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img5} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="details-box">
                      <div className="project-box project-details-box">
                        <img src={Img6} alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ServiceInterior;
