import React, {Fragment} from 'react'
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import ServiceInterior from '../../components/ServiceInterior'
import Footer from '../../components/footer'



const ServiceInteriorPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'インテリアワーキング事業'} pagesub={'Interior Working'}/>
            <ServiceInterior/>
            <Footer/>
        </Fragment>
    )
};
export default ServiceInteriorPage;
